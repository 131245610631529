import { registerComponent } from './components-front'

function setImageOverlayHeight(){
    $('.overlay-container').each(function(){
        let overlay_container = $(this);
        let overlay_img_wrapper = $(this).find('.overlay-image');
        let overlay_img = $(this).find('.overlay-image img');
        let overlay_content = $(this).find('.overlay-content');
        overlay_container.css('height', overlay_content.outerHeight() + 30);
        overlay_img_wrapper.css('height', "100%");
        overlay_img_wrapper.css('max-height', "100%");
        overlay_img.css('height', "100%");
        overlay_img.css('max-height', "100%");
    })
}

registerComponent('image-overlay', function(){
    //Overlay Height
    $(window).resize(function(){
        setImageOverlayHeight();
    });
    setImageOverlayHeight();
});