$(document).ready(function() {
    let quantitySubtract = document.querySelectorAll('[quantity-adjust] [subtract]');
    if (quantitySubtract.length > 0) {
        let quantityAdd = document.querySelectorAll('[quantity-adjust] [add]');
        let getButton = document.querySelectorAll('[get-button]');
        let updateQuantity = function(e) {
            e.preventDefault();
            let direction = this.getAttribute('data-direction');
            let container = this.parentNode;
            let value = parseInt(container.querySelector('input').value);

            if (direction === 'down') {
                if (value < 1) {
                    value = 0;
                } else {
                    value = value - 1;
                }
            } else if (direction === 'up') {
                value++;
            }
            container.querySelector('input').value = value;
            container.querySelector('span').textContent = value;
        }
        for (let i = 0; i < quantitySubtract.length; i++) {
            quantitySubtract[i].addEventListener('click', updateQuantity);
        }
        for (let i = 0; i < quantityAdd.length; i++) {
            quantityAdd[i].addEventListener('click', updateQuantity);
        }
        for (let i = 0; i < getButton.length; i++) {
            getButton[i].addEventListener('click', function() {
                let form = document.querySelector('[cart-table]');
                let input = form.querySelector('[button-pressed]');
                input.name = this.name;
                input.value = this.value;
            });
        }
    }
});