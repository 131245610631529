import moment from 'moment'
import { registerComponent } from './components-front'

const excerpt = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    const text = div.textContent
    const match = text.match(/^\s*(?:\S+\s*){1,15}/u)
    if (match) {
        return match[0].trimRight() + '...'
    }
    return text
}

registerComponent('blog-posts', _ => {
    document.querySelectorAll('.component-blog-posts').forEach(comp => {
        const loadMore = comp.querySelector('.blog-posts-load-more-btn')
        if (loadMore) {
            loadMore.addEventListener('click', _ => {
                const page = loadMore.dataset.page
                const displayAmount = parseInt(loadMore.dataset.displayAmount)
                const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

                loadMore.setAttribute('disabled', 'disabled')
                loadMore.style.cursor = 'wait'

                const formData = new FormData();
                formData.append('display_amount', displayAmount)

                fetch(`/componentblogposts/paginate?page=${page}`, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': csrf,
                    },
                    body: formData,
                })
                    .then(response => response.json())
                    .then(({ data: posts, current_page: currentPage, last_page: lastPage }) => {
                        if (lastPage === currentPage) {
                            loadMore.classList.add('d-none')
                            loadMore.classList.remove('d-flex')
                        }
                        loadMore.dataset.page = Math.max(...[currentPage, lastPage].map(v => +v))
                        loadMore.removeAttribute('disabled')
                        loadMore.style.cursor = 'pointer'

                        const postsWrapper = comp.querySelector('.blog-posts-wrapper')
                        /** @var {HTMLTemplateElement} template */
                        const template = comp.querySelector('#blog-posts-item')

                        /**
                         * @var {Object} post
                         * @var {String} post.title
                         * @var {String} post.slug
                         * @var {String} post.content
                         * @var {String|undefined} post.secure_url
                         * @var {String} post.created_at
                         */
                        posts.forEach(post => {
                            const postEl = template.content.cloneNode(true)
                            if (post.secure_url) {
                                postEl.querySelector('img').setAttribute('src', post.secure_url)
                            }
                            postEl.querySelector('#post-title').textContent = post.title
                            postEl.querySelector('#post-date').textContent = moment(post.created_at).format('Do MMMM, Y')
                            postEl.querySelector('#post-excerpt').textContent = excerpt(post.content)
                            postEl.querySelector('a').setAttribute('href', `/blog/${post.slug}`)

                            postsWrapper.append(postEl)
                        })
                    })
            })
        }
    })
})
