<template>
	<div class="vue-archive">
		<div :class="parentClass">

			<div v-if="isLoading">
				<div class="col-auto p-5 my-3 text-center border">
					<h2 class="text-muted">Loading</h2>
				</div>
			</div>

			<div v-if="!hasResults" class="row justify-content-center mb-5">
				<div class="col-auto p-5 my-3 text-center border">
					<h2 class="text-muted">No Matching Results</h2>
					<p>Sorry! We haven't been able to find any results for your search.</p>
				</div>
			</div>

			<div class="row align-items-center p-5 vue-archive-item" v-for="post, index in posts.data" :key="post.id">
				<div :class="{'col-md-7 order-last order-md-last': (index - ((page - 1) * limit)) % 2 == 0, 'col-md-7 order-last order-md-first': (index - ((page - 1) * limit)) % 2 != 0}">
					<p><strong>{{ post.title }}</strong></p>
					<p>{{ post.post.excerpt }}</p>
					<div :class="{'row justify-content-center justify-content-md-end': (index - ((page - 1) * limit)) % 2 == 0, 'row justify-content-center justify-content-md-start': (index - ((page - 1) * limit)) % 2 != 0}">
						<div :class="{'col-auto order-last order-md-last': (index - ((page - 1) * limit)) % 2 == 0, 'col-auto order-last order-md-first': (index - ((page - 1) * limit)) % 2 != 0}">
							<a class="btn btn-secondary mt-1" :href="makeItemLink(post)">Read More</a>
						</div>
						<component-social></component-social>
					</div>
				</div>
				<div class="col-md-5 order-first">
					<div class="embed-responsive embed-responsive-16by9">
						<a :href="makeItemLink(post)">
						<img class="embed-responsive-item" :src="(post.featured_image) ? post.featured_image : '/uploads/images/web/history.png'" :alt="post.title">
						</a>
					</div>
				</div>
			</div>
			<div class="row py-5 justify-content-center border-top border-secondary">
				<pagination class="col-auto vue-pagination" :limit="limit" :data="posts" @pagination-change-page="getResults">
					<li slot="prev-nav">&lt;</li>
					<li slot="next-nav">&gt;</li>
				</pagination>
			</div>


		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			posts: {},
			page: 1,
			parentClass: 'loading',
		};
	},
	props: {
		url: {type: String},
		count: {default: 6, type: Number},
		limit: {default: 2, type: Number},
	},
	

	methods: {
		dynamicAttribute: function(prefix, index, key) {
			return prefix + index + "-" + key;
		},
		interpolateAttribute: function(value, prefix = "", suffix = "") {
			return prefix + value + suffix;
		},
		getResults(page = 1) {
			this.parentClass = "loading";
			axios.get(this.url + '?page=' + page + '&count=' + this.count)
				.then(response => {
					this.posts = response.data;
					this.page = page;
					this.parentClass = "loaded";
				});
		},
		makeItemLink(item) {
			var slug = this.interpolateAttribute(item.slug, '/');
			if (typeof item.prefix !== 'undefined' && item.prefix !== null) {
				slug = this.interpolateAttribute(item.prefix, '/', slug);
			}
			return slug;
		}
	},
	computed: {
		isLoading: function () {
      return (this.parentClass == 'loading');
		},
		hasResults: function (){
			if(this.parentClass == 'loading'){
				return true;
			}
			if(typeof this.posts.data != 'undefined'){
				if(this.posts.data.length > 0){
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		this.getResults();
	},
	updated() {}
};
</script>
