/**
 *
 * Load: Search DOM for all radio inputs.
 * Loop through and check if radio has data-radio-group attribute and is checked
 * Enable the input [number] which is associated with the radio by data-target attribute
 * Disable others from same group
 *
 * Radio Change: Run same function as above
 *
 */

$(document).ready(function() {
	$("input[type=radio]").on("change", function() {
		radioGroupDisable($(this));
	});
	radioGroupInit();

	$("form select").on("change", function() {
		updateFloatingLabelSelect();
	});
});

function radioGroupDisable(elem) {
	if ($(elem).data("radio-group")) {
		let target = $(elem).data("target");
		$("[data-radio-group][type=number]").each(function() {
			$(this).prop("disabled", true);
		});

		$(target).prop("disabled", false);
	}
}

function radioGroupInit() {
	$("input[type=radio").each(function() {
		if ($(this).data("radio-group") && $(this).prop("checked")) {
			radioGroupDisable($(this));
		}
	});
}

function updateFloatingLabelSelect() {
	$("form select").each(function() {
		if (this.selectedIndex != 0) {
			$(this).addClass("selected");
		} else {
			$(this).removeClass("selected");
		}
	});
}

/**
 *
 * Toggle the visibility and required state of the new / used input
 *
 */
$(document).ready(function() {
	$("input[type=radio][name=buy_hire]").on("change", function() {
		if ($(this).data("method") === "buy") {
			let target = $(this).data("target-toggle");
			$(target).show();
			const types = new Array("input", "select", "textarea", "radio");

			types.forEach(function(type) {
				$(target + " " + type).prop("required", true);
			});
		}
	});
	updateFloatingLabelSelect();
});

// Resizes textarea when overflowing
$(document).ready(function() {
	var text = $('textarea');
	text.on('change drop keydown cut paste', function() {
	text.height('auto');
		text.height(text.prop('scrollHeight'));
	});
});