<template>
    <div class="d-none d-md-block social-wrapper"> 
        <a class="social-share-btn" :href="'https://www.facebook.com/sharer/sharer.php?u=' + url + '&display=popup'" onclick="return !window.open(this.href, 'Share to Facebook', 'width=800,height=800')">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 47 47">
                <path d="M23.233,46.993c.09,0,.177.013.267.013s.177-.012.267-.013ZM47,23.506A23.5,23.5,0,1,0,21.031,46.875V29.839H15.258V23.154h5.773v-4.93c0-5.721,3.494-8.836,8.6-8.836a47.514,47.514,0,0,1,5.158.263V15.63l-3.54,0c-2.776,0-3.313,1.319-3.313,3.254v4.268h6.62l-.862,6.685H27.934V46.575A23.5,23.5,0,0,0,47,23.506"
                    transform="translate(0 -0.006)" style="fill:#B2EDF8" />
            </svg>
        </a>
        
        <a class="social-share-btn" :href="'https://www.linkedin.com/shareArticle?mini=true&url='+ url +'&source=LinkedIn'" onclick="return !window.open(this.href, 'Share to LinkedIn', 'width=800,height=800')">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 47 47">
                <path d="M37.274,37.252H31.567v-8.93c0-2.13-.036-4.869-2.965-4.869-2.97,0-3.423,2.321-3.423,4.716v9.083h-5.7V18.889h5.471V21.4h.079a5.993,5.993,0,0,1,5.4-2.966c5.779,0,6.847,3.8,6.847,8.748ZM16.351,13.07a3.31,3.31,0,1,1-3.308-3.31,3.31,3.31,0,0,1,3.308,3.31m-6.166,5.819H15.9V37.252H10.185ZM47,23.506a23.5,23.5,0,1,0-23.5,23.5A23.5,23.5,0,0,0,47,23.506"
                    transform="translate(-0.003 -0.006)" style="fill:#B2EDF8" />
            </svg>
        </a>
    </div>
</template>


<script>
export default {
	props: ["url"],
};
</script>