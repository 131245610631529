$(document).ready(function() {
    let buttonOptions = document.querySelectorAll('[button-option]');
    for (let i = 0; i < buttonOptions.length; i++) {
        buttonOptions[i].addEventListener('click', function(e) {
            // e.preventDefault();
            let field = document.querySelector('[name="button_option"]');
            field.value = this.value;
        });
    }
    let userForms = document.querySelectorAll('[user-form]');
    for (let i = 0; i < userForms.length; i++) {
        userForms[i].addEventListener("submit", function(e) {
            e.preventDefault();

            let form = this;
            let fields = form.querySelector('[user-form-fields]');
            let error = form.querySelector('[user-form-errors]');
            let success = form.querySelector('[user-form-success]');

            error.classList.add('d-none');
            fields.style.opacity = 0.5;
            fields.style.pointerEvents = 'none';

            grecaptcha.ready(function() {
                grecaptcha.execute(window.recaptcha_site_key, {action: 'submission'}).then(function(token) {
                    fields.querySelector('.g-recaptcha input[name="g-recaptcha-response"]').value = token;
                    let formData = new FormData(form)
                    let request = new XMLHttpRequest;

                    request.onreadystatechange = function() {
                        if (this.readyState === this.DONE) {
                            let response = JSON.parse(this.responseText);
                            if (response.success) {
                                if (response.action === 'refresh') {
                                    window.location.reload();
                                } else if (response.action === 'redirect') {
                                    window.location = response.path;
                                } else {
                                    fields.classList.add('d-none');
                                    success.textContent = response.message;
                                    success.classList.remove('d-none');
                                }
                            } else {
                                fields.style.opacity = 1;
                                fields.style.pointerEvents = 'auto';
                                error.textContent = response.message;
                                error.classList.remove('d-none');
                            }
                        }
                    }
                    request.open("POST", form.getAttribute('action'));
                    request.send(formData);
                });
            });
        }, false)
    }
});