$(function () {
	'use strict'

	$('[data-toggle="offcanvas"]').on('click', function () {
		$('.offcanvas-collapse').toggleClass('open');
		$('html').toggleClass('no-scroll');
	})
})

$(function () {
	'use strict'

	$('[data-toggle="modal"]').on('click', function () {
		$('html').addClass('no-scroll');
	})
	$('[data-dismiss="modal"]').on('click', function () {
		$('html').removeClass('no-scroll');
		$('.offcanvas-collapse').removeClass('open');
		// $('html').toggleClass('no-scroll');
	})
})