import { registerComponent } from './components-front'

registerComponent('image-to-svg', function() {
    [...document.querySelectorAll('img')].filter(img => {
        return img.classList.contains('svg-replace');
    }).forEach(img => {
        const id = img.getAttribute('id')
        const src = img.getAttribute('src');
        const classList = [...img.classList];
        const style = img.getAttribute('style')

        fetch(src)
            .then(response => response.text())
            .then(data => {
                const svg = createElement(data);
                svg.classList.add(...classList);
                svg.id = id || '';
                if (svg.hasAttribute('style')) {
                    svg.setAttribute('style', svg.getAttribute('style') + ';' + style)
                } else {
                    svg.setAttribute('style', style)
                }
                img.parentNode.insertBefore(svg, img);
                img.parentNode.removeChild(img);
            })
            .catch(err => console.error(err));
    })
});

function createElement(html) {
    const frag = document.createDocumentFragment();

    const elem = document.createElement('div');
    elem.innerHTML = html;

    while (elem.childNodes[0]) {
        frag.appendChild(elem.childNodes[0]);
    }
    return frag.childNodes[0];
}
