import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

registerComponent('event-carousel', _ => {
    document.querySelectorAll('.event-carousel-wrapper').forEach(carouselWrapper => {
        const carousel = carouselWrapper.querySelector('.event-carousel')
        const cols = carousel.dataset.cols || 3

        /** @var {HTMLDivElement} prev */
        const prev = carouselWrapper.querySelector('.prev')
        /** @var {HTMLDivElement} next */
        const next = carouselWrapper.querySelector('.next')

        const slider = tns({
            container: carousel,
            items: Math.max(1, Math.floor(cols / 3)),
            slideBy: 1,
            gutter: 20,
            nav: false,
            prevButton: prev,
            nextButton: next,
            arrowKeys: true,
            lazyload: true,
            responsive: {
                576: {
                    items: Math.max(2, Math.floor(cols / 2)),
                },
                768: {
                    items: Math.max(3, Math.floor(cols * 0.5)),
                },
                992: {
                    items: cols,
                    gutter: 30,
                },
                1200: {
                    gutter: 40,
                },
            },
        })
    })
})
