addEventListener('DOMContentLoaded', _ => {
    Object.defineProperty(document, 'top', {
        get() {
            return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
        },
    })
    const navElement = document.querySelector('#navigation')
    const nextSibling = navElement.parentElement.querySelector('#navigation + *')
    const mirror = navElement.cloneNode(true)
    mirror.classList.add('mirror')
    navElement.parentElement.insertBefore(mirror, nextSibling)
    addEventListener('scroll', _ => {
        if (document.top > navElement.clientHeight) {
            navElement.classList.add('scrolled')
        } else {
            navElement.classList.remove('scrolled')
        }
    })

    const hamburgerToggles = document.querySelectorAll('.nav-toggle')
    hamburgerToggles.forEach(hamburger => {
        hamburger.addEventListener('click', ev => {
            ev.preventDefault();
            const navItems = document.querySelector(ev.target.getAttribute('data-nav-items'));
            navItems.classList.toggle('open')
            ev.target.classList.toggle('opened')

            if (ev.target.id && ev.target.id == 'primary-nav-toggle' && !navItems.classList.contains('open')) {
                const nestedNavToggles = document.querySelectorAll('.navigation-items .nav-toggle');
                const nestedNavItems = document.querySelectorAll('.navigation-items .navigation-items');

                nestedNavToggles.forEach(toggle => {
                    toggle.classList.remove('opened');
                });

                nestedNavItems.forEach(item => {
                    item.classList.remove('open');
                });
            }
        });
    });
})
