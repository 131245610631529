/* -----------------------------
 * CLASS DECLARATIONS
------------------------------ */
// ProductCategory will reflect the currently selected Category and its parameters
// class ProductCategory {
//     constructor(id, hire, buy, conditionNew, conditionUsed, prodCount = 0) {
//         this.id = id;
//         this.hire = hire;
//         this.buy = buy;
//         this.conditionNew = conditionNew;
//         this.conditionUsed = conditionUsed;
//         this.prodCount = prodCount;
//     }
// }


// // Product will reflect the currently selected Product and its parameters
// class Product {
//     constructor(id, name, cat, hire, buy, conditionNew, conditionUsed, img){
//         this.id = id;
//         this.name = name;
//         this.cat = cat;
//         this.hire = hire;
//         this.buy = buy;
//         this.conditionNew = conditionNew;
//         this.conditionUsed = conditionUsed;
//         this.img = img;
//     }
// }

function createProductCategory(id = null, hire = null, buy = null, conditionNew = null, conditionUsed = null, prodCount = 0){
    var temp = {};

    temp.id = id;
    temp.hire = hire;
    temp.buy = buy;
    temp.conditionNew = conditionNew;
    temp.conditionUsed = conditionUsed;
    temp.prodCount = prodCount;
    
    return temp;
}

function createProduct(id = null, name = null, cat = null, hire = null, buy = null, conditionNew = null, conditionUsed = null, img = null){
    var temp = {};

    temp.id = id;
    temp.name = name;
    temp.cat = cat;
    temp.hire = hire;
    temp.buy = buy;
    temp.conditionNew = conditionNew;
    temp.conditionUsed = conditionUsed;
    temp.img = img;
    
    return temp;
}

/* -----------------------------
 * GENERIC UTILITY FUNCTIONS
------------------------------ */
// Jquery functions
(function( $ ){
    $.fn.getLabel = function(){
        var result = $(this).closest('form').find("label[for='"+$(this).attr('id')+"']")
        if(typeof result == undefined){
            result = null;
        }
        return result;
    }
    $.fn.setEnabled = function() {
        $(this).prop('disabled', false);
    }; 
    $.fn.setDisabled = function() {
        $(this).prop('disabled', true);
    }; 
    $.fn.setRequired = function(){
        $(this).prop('required', true);
    };
    $.fn.unsetRequired = function(){
        $(this).prop('required', false);
    };
    // Enable the input and set as required
    $.fn.enableRequired = function(){
        $(this).show();
        $(this).parent().show();
        $(this).setEnabled();
        $(this).setRequired();
    }
    $.fn.disableRequired = function(){
        $(this).hide();
        $(this).parent().hide();
        $(this).setDisabled();
        $(this).unsetRequired();
    }
    // Generic function to reset selected value
    $.fn.resetSelected = function(){
        $(this).prop("selectedIndex", 0);
        $(this).removeClass("selected");
    }
    // Generic function to show option with IOS fix
    $.fn.showOpt = function(){
        if( ($(this).parent().is('span')) ) $(this).unwrap();
        $(this).prop('disabled', false) ;
        $(this).show();
    }
    // Generic function to Hide option with IOS fix
    $.fn.hideOpt = function(){
        if(!($(this).parent().is('span'))) $(this).wrap('<span>');
        $(this).prop('disabled', true) ;
        $(this).hide();
    }
    // Generic function to set option as selected in dropdown
    $.fn.setSelected = function(){
        $(this).closest('select').find('option:selected').prop("selected", false);
        $(this).prop("selected", true);
        $(this).closest("select").addClass("selected");
    }
 })( jQuery );

// Utility function to hide the product image
function hideProdImg(){
    $("#raq_form #raq_prod_feat").hide();
    $("#raq_form #raq_prod_feat img").attr('src', "");
    $("#raq_form #raq_prod_feat img").attr('alt', "");
}
// Utility function to display the product image for the active product
function showProdImg(){
    $("#raq_form #raq_prod_feat img").attr('src', activeProd.img);
    $("#raq_form #raq_prod_feat img").attr('alt', activeProd.name);
    $("#raq_form #raq_prod_feat").show();
}

/* -----------------------------
 * FORM SPECIFIC FUNCTIONS
------------------------------ */
// Populate ProductCategory with selected category details
function popCat(){
    // Get Checked Category
    var checked = $("#raq_form input[name='category']:checked");

    // If no category has been selected
    if (!checked.val()) {
        // activeCat = new ProductCategory();
        // activeProd = new Product();
        activeCat = createProductCategory();
        activeProd = createProduct();
    }
    // If a category has been selected
    else {
        var id = checked.data('id');
        var hire = checked.data('hire');
        var buy = checked.data('buy');
        var conditionNew = checked.data('new');
        var conditionUsed = checked.data('used');
        activeCat = createProductCategory(id, hire, buy, conditionNew, conditionUsed);
    }
}

// Display or hide Product Categories dependant on selected category
function catDisplay(){
    checked = $("#raq_form input[name='category']:checked");
    $("#raq_form label.raq-cat-wrapper.selected").removeClass('selected');
    // If no category is checked then show all categories and hide the clear all button 
    if (!checked.val()) {
        $("#raq_form label.raq-cat-wrapper").show();
        $("#raq_form #btn-quote-cat-clear").hide();
    } 
    // If category is selected, add selected class, hide other categories and add clear all button 
    else {
        $("#raq_form label.raq-cat-wrapper").hide();
        $("#raq_form #btn-quote-cat-clear").show();
        checked.closest("#raq_form label.raq-cat-wrapper").show();
        checked.closest("#raq_form label.raq-cat-wrapper").addClass('selected');
    }
}

// Populate the product option with the selected product values
function popProd(){
    var selected = $('#raq_form #raq-products').find("option:selected");
    // If no product has been selected
    if (!selected.val()) {
        // activeProd = new Product();
        activeProd = createProduct();
    }
    // If a product has been selected
    else {
        var id = selected.data('id');
        var name = selected.val();
        var cat = selected.data('cat');
        var hire = selected.data('hire');
        var buy = selected.data('buy');
        var conditionNew = selected.data('new');
        var conditionUsed = selected.data('used');
        var img = selected.data('featured-image');
        activeProd = createProduct(id, name, cat, hire, buy, conditionNew, conditionUsed, img);
    }
}

// Display Products dependant on Product Category currently selected
function prodDisplay(){
    var select = $('#raq_form #raq-products');
    var catProds = [];
    select.disableRequired();
    if(activeCat.id != null){
        select.find('option:not(.disabled-select)').each(function(){
            var opt = $(this);
            var prodCats = opt.data('cat');
            opt.hideOpt();
            $.each(prodCats, function(index, value){
                if(value == activeCat.id){
                    catProds.push(opt)
                    opt.showOpt();
                }
            })
        })
        if(catProds.length > 0){
            activeCat.prodCount = catProds.length;
            select.enableRequired();
        } else {
            activeCat.prodCount = 0;
        }
    } else {
        select.disableRequired();
        select.resetSelected();
    }
}

// Determine whether to display or hide product image dependant on selected product
function displayProdImg(){
    var image = activeProd.img;
    hideProdImg()
    if(typeof image != 'undefined' && image != null){
        $.ajax({
            url: image,
            type: "HEAD",
            error: function () {
                hideProdImg()
            },
            success: function(){
                showProdImg(image)
            }
        });
    }
}

// Determine whether to display the hire/buy select
function displayHireBuy(){
    var select = $("#raq_form #buy_hire");
    select.disableRequired();
    // If Category Selected
    if(activeCat.id != null){
        // If Category has products
        if(activeCat.prodCount > 0){
            if(activeProd.id != null){
                if(activeProd.hire || activeProd.buy){
                    displayHireBuyOpts(activeProd);
                    select.enableRequired();
                } else {
                    select.disableRequired();
                }
            }
        }
        // If Category has no products
        else {
            if(activeCat.hire || activeCat.buy){
                displayHireBuyOpts(activeCat);
                select.enableRequired();
            } else {
                select.disableRequired();
            }
        }
    }
}

// Determine options to display in the hire/buy select
function displayHireBuyOpts(obj){
    var hire = $("#raq_form #raq_buy_hire_wrapper").find("option[value=Hire]")
    var buy = $("#raq_form #raq_buy_hire_wrapper").find("option[value=Purchase]")
    if(obj.hire == 1){
        hire.showOpt();
        if(obj.buy != 1){
            hire.setSelected()
        }
    } else {
        hire.hideOpt();
    }
    if(obj.buy == 1){
        buy.showOpt();
        if(obj.hire != 1){
            buy.setSelected();
        }
    } else {
        buy.hideOpt();
    }
}

// Conditionally display the condition select field
function displayCondition(){
    var select = $("#raq_form #raq_new_used");
    select.disableRequired()
    // If Buy selected
    if($("#raq_form #raq_buy_hire_wrapper").find("option:selected").val() == "Purchase"){
        // If Category Selected
        if(activeCat.id != null){
            // If Category has products
            if(activeCat.prodCount > 0){
                if(activeProd.id != null){
                    if(activeProd.conditionNew || activeProd.conditionUsed){
                        displayConditionOpts(activeProd);
                        select.enableRequired();
                    } else {
                        select.disableRequired();
                    }
                }
            }
            // If Category has no products
            else {
                if(activeCat.conditionNew || activeCat.conditionUsed){
                    displayConditionOpts(activeCat);
                    select.enableRequired();
                } else {
                    select.disableRequired();
                }
            }
        }
    }
}

// Determine options to display in the condition select
function displayConditionOpts(obj){
    var conditionNew = $("#raq_form #raq_new_used").find("option[value=New]");
    var conditionUsed = $("#raq_form #raq_new_used").find("option[value=Used]");
    if(obj.conditionNew == 1){
        conditionNew.showOpt();
        if(obj.conditionUsed != 1){
            conditionNew.setSelected();
        }
    } else {
        conditionNew.hideOpt();
    }
    if(obj.conditionUsed == 1){
        conditionUsed.showOpt();
        if(obj.conditionNew != 1){
            conditionUsed.setSelected();
        }
    } else {
        conditionUsed.hideOpt();
    }
}

// Generic function wrappers to run primary functions in sequence
function popAll(){
    //Category
    popCat();
    catDisplay();
    // Product
    popProd();
    prodDisplay();
    displayProdImg();
    // Purchase Options
    displayHireBuy();
    displayCondition();
    
}
function prodSelected(){
    // Product
    popProd();
    prodDisplay();
    displayProdImg();
    // Purchase Options
    displayHireBuy();
    displayCondition();
}
function hireBuySelected(){
    // Purchase Options
    displayHireBuy();
    displayCondition();
}

/* -----------------------------
 * Standalone Form Runtime Logic
------------------------------ */
//INITIALISE ON DOCUMENT READY
// var activeCat = new ProductCategory();
// var activeProd = new Product();
var activeCat = createProductCategory();
var activeProd = createProduct();
var checked = $("#raq_form input[name='category']:checked");

$(document).ready(function(){
    // Initialize empty ProductCategory
    popAll();

    // Selecting a Category, Product
    $(document).on('click', '#raq_form label.raq-cat-wrapper:not(.selected)', function(e){
        e.preventDefault();
        $(this).find("input[type=radio]").not(':checked').prop("checked", true).change();
    })

    $(document).on('change', '#raq_form .raq-category', function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        $("#raq_form #raq-products").resetSelected();
        $("#raq_form #buy_hire").resetSelected();
        $("#raq_form #raq_new_used").resetSelected();
        popAll();
        return false;
    })
    $(document).on('change', '#raq_form #raq-products', function(e){
        $("#raq_form #buy_hire").resetSelected();
        $("#raq_form #raq_new_used").resetSelected();
        prodSelected();
        return false;
    })
    $(document).on('change', '#raq_form [name=buy_hire]', function(e){
        $("#raq_form #raq_new_used").resetSelected()
        hireBuySelected();
        return false;
    })


    // Clearing Category Selection
    $(document).on('click', '#raq_form #btn-quote-cat-clear, #raq_form label.raq-cat-wrapper.selected', function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        clearCatSelection();
        return false;
    });

    function clearCatSelection(){
        $("#raq_form input[name='category']").prop("checked", false);
        $("#raq_form #raq-products").resetSelected();
        $("#raq_form #buy_hire").resetSelected();
        $("#raq_form #raq_new_used").resetSelected();
        popAll();
    }
});

/* -----------------------------
 * Product Page Form Functions
 ---------------------------- */
function productEnableNewUsed(){
    if($("#product_quote_form [name=buy_hire]:checked").val() == "Purchase"){
        $("#product_quote_form #raq_new_used").parent().show();
        $("#product_quote_form #raq_new_used").enableRequired();
        // $("#product_quote_form #raq_new_used").resetSelected();
    } else {
        $("#product_quote_form #raq_new_used").parent().hide();
        $("#product_quote_form #raq_new_used").disableRequired();
        $("#product_quote_form #raq_new_used").resetSelected();
    }
}

$(document).ready(function(){
    productEnableNewUsed();
    $(document).on("change", "#product_quote_form [name=buy_hire]", function(){
        productEnableNewUsed();
        return false;
    })
})


/* -----------------------------
 * General Form Functions
 ---------------------------- */
// Clear invalid class from inputs on change of value
// Clears email and email confirmation if either is changed and both match
$(document).on('change', ".is-invalid", function(){
    if( ($(this).attr("name") == "email") || ($(this).attr("name") =="email_confirmation") ){
        if( $(this).closest("form").find("[name=email]").val() ==  $(this).closest("form").find("[name=email_confirmation]").val())
        $(this).closest("form").find("[name=email], [name=email_confirmation]").removeClass("is-invalid");
    } else {
        $(this).removeClass("is-invalid");
    }
});