import { registerComponent } from './components-front'

registerComponent('booking-form', _ => {
    document.querySelectorAll('[data-type=ComponentBookingForm]').forEach(comp => {
        comp.querySelectorAll('[required][placeholder]').forEach(input => {
            input.setAttribute('placeholder', input.getAttribute('placeholder') + ' *')
        })

        const modal = comp.querySelector('.booking-form-modal')
        const formWrap = modal.firstElementChild
        if (modal) {
            const autoLoad = comp.querySelector('.auto-load')
            const btn = comp.querySelector('.booking-form-modal-btn')
            const close = modal.querySelector('.modal-close')
            if (autoLoad) {
                modal.classList.remove('d-none')
                modal.classList.add('d-flex')
            }
            if (btn) {
                btn.addEventListener('click', _ => {
                    modal.classList.remove('d-none')
                    modal.classList.add('d-flex')
                })
            }

            if (close) {
                close.addEventListener('click', _ => {
                    modal.classList.add('d-none')
                    modal.classList.remove('d-flex')
                })
            }

            modal.addEventListener('click', ev => {
                if (!formWrap.contains(ev.target)) {
                    modal.classList.add('d-none')
                    modal.classList.remove('d-flex')
                }
            })
        }
    })
})
