import { registerComponent } from '../components-front';

registerComponent('revolving-carousel', _ => {
    document.querySelectorAll('.revolving-carousel').forEach(carousel => {
        const id = `revolving-carousel-${new Date().valueOf()}`
        carousel.id = id
        const perRow = carousel.dataset.perRow
        const style = document.createElement('style')
        style.innerHTML = `#${id} .row {
            grid-template-columns: repeat(${perRow * 2}, minmax(11vw, 220px));
        }
        
        @media (max-width: 991.98px) {
            #${id} .row {
                grid-template-columns: repeat(${perRow * 2}, minmax(15vw, 220px));
            }
        }`
        carousel.insertBefore(style, carousel.firstElementChild)
        carousel.querySelectorAll('.row').forEach((row, i) => {
            row.dataset.width = row.scrollWidth
            const children = [...row.children]
            children.forEach(item => {
                const clone = item.cloneNode(true)
                clone.classList.add('clone')
                row.appendChild(clone)
            })
            row.dataset.left = 0
            if (i % 2) {
                row.dataset.left = row.dataset.width * -1
            }
            row.style.transition = 'left 1s linear'
            row.dataset.interval = setInterval(() => {
                if (row.style.transition === 'unset') {
                    row.style.transition = 'left 1s linear'
                }
                if (i % 2) {
                    row.dataset.left = +row.dataset.left + 10
                    if (row.dataset.left >= 0) {
                        row.style.transition = 'unset'
                        row.dataset.left = row.dataset.width * -1
                    }
                } else {
                    row.dataset.left = +row.dataset.left - 10
                    if (row.dataset.left <= row.dataset.width * -1) {
                        row.style.transition = 'unset'
                        row.dataset.left = 0
                    }
                }
                row.style.left = `${row.dataset.left}px`
            }, 1000)
        })
    })
})
