// Function to set the phone field as required in the form when preferred contact method is set to phone
function setPhoneRequiredIfPreferred(){
    $("#inputContactMethod option:selected").each(function(){
        var contactMethod = $(this).val();        
        if(contactMethod == "Phone"){
            $(this).closest("form").find("#inputPhone").prop('required',true);
        }else if(contactMethod == "Email"){
            $(this).closest("form").find("#inputPhone").prop('required',false);
        }
    })
}

// Function to find multiline labels and adjust the input/textarea to prevent overlap/overflow
function findLabelOverflow (){
    $(".form-label-group label:not(.dropdown-label)").each(function(){
        var ow = $(this).outerWidth();
        var sw = $(this)[0].scrollWidth;
        var matchedInput = $("#" + $(this).attr("for"));
        matchedInput.css("padding-top", "");
        matchedInput.css("height", "");
        if(sw > ow || $(this).hasClass('multi-line-label')){
            $(this).addClass('multi-line-label');
            matchedInput.css("height", "");
            var labelHeight = $(this).height();
            var inputHeight = matchedInput.height();
            var contentHeight = matchedInput.get(0).scrollHeight;
            if(contentHeight > inputHeight){
                contentHeight = inputHeight;
            }
            matchedInput.css("padding-top", labelHeight);
            matchedInput.css("height", inputHeight * 2 + labelHeight);
        } 
    })
}
function findSelectLabelOverflow(){
    $(".form-label-group label.dropdown-label").each(function(){
        var ow = $(this).outerWidth();
        var sw = $(this)[0].scrollWidth;
        var matchedInput = $("#" + $(this).attr("for"));
        matchedInput.css("padding-top", "");
        matchedInput.css("height", "");

        if(matchedInput.hasClass('selected')){
            if(sw > ow || $(this).hasClass('multi-line-label')){
                $(this).addClass('multi-line-label');
                matchedInput.css("height", "");
                var labelHeight = $(this).height();
                var inputHeight = matchedInput.height();
                var contentHeight = matchedInput.get(0).scrollHeight;
                if(contentHeight > inputHeight){
                    contentHeight = inputHeight;
                }
                matchedInput.css("padding-top", labelHeight);
                matchedInput.css("height", inputHeight * 2 + labelHeight);
            } 
        } else {
            $(this).removeClass('multi-line-label')
        }
    })
}



$( document ).ready(function() {  
    // Handle phone field required dependant on preferred contact method selected (on load and on change)
    setPhoneRequiredIfPreferred();
    $( "#inputContactMethod" ).change(function() {
        setPhoneRequiredIfPreferred();
    });   
    // Handle multiline floating labels
    findLabelOverflow();
    findSelectLabelOverflow()
    $( window ).resize(function() {
        findLabelOverflow();
        findSelectLabelOverflow()
    })
    $(window).on('shown.bs.modal', function() { 
        findLabelOverflow();
        findSelectLabelOverflow()
    });

    $(document).on('change', ".form-label-group select", function(){
        //FLOATING LABEL SAFARI FIX
        val = $(this).find(":selected").val();
        if( typeof val != 'undefined' && val.length > 0 ){
            $(this).addClass('selected');
        } else {
            $(this).removeClass('selected');
        }

        // Floating Label Sizing
        findSelectLabelOverflow()
    })
});