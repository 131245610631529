import { registerComponent } from './components-front'

registerComponent('image-link', function() {
    $('a.img-link-video.youtube-link').click(function() {
        let sub_id = $(this).data('sub');
        let vid_id = $(this).data('vid');
        autoPlayVideo(sub_id, vid_id);
    }); 

    function autoPlayVideo(sub_id, vcode){
        "use strict";
        $("#video-popup-modal-" + sub_id + " .embed-responsive").html('<iframe allow="autoplay" src="https://www.youtube.com/embed/'+vcode+'?autoplay=1&loop=1&rel=0&wmode=transparent" frameborder="0" allowfullscreen wmode="Opaque"></iframe>');
    }

    $('.img-link-video.hero-video-modal .close').click(function(e) {
        e.preventDefault();
        $('.hero-video-modal').find('iframe').remove();
    });

    // Close video modal when clicking outside
    $('.modal').on('hidden.bs.modal', function (e) {
        $('.close').trigger('click');
    })
});