import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

registerComponent('testimonial-carousel', _ => {
    document.querySelectorAll('.testimonial-carousel-wrapper').forEach(carouselWrapper => {
        const carousel = carouselWrapper.querySelector('.testimonial-carousel')
        const cols = carousel.dataset.cols || 2

        /** @var {HTMLDivElement} prev */
        const prev = carouselWrapper.querySelector('.prev')
        /** @var {HTMLDivElement} next */
        const next = carouselWrapper.querySelector('.next')

        const slider = tns({
            container: carousel,
            items: 1,
            slideBy: 1,
            nav: false,
            prevButton: prev,
            nextButton: next,
            arrowKeys: true,
            responsive: {
                768: {
                    items: cols,
                    gutter: 56,
                },
            },
        })
    })
})