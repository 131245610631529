import { tns } from 'tiny-slider'
import { registerComponent } from './components-front'

registerComponent('universal-grid', _ => {
    document.querySelectorAll('.universal-grid-slider-item .slider').forEach(slider => {
        tns({
            container: slider,
            items: 1,
            slideBy: 1,
            controls: false,
            navPosition: 'bottom',
            autoplay: true,
            autoplayButtonOutput: false,
        })
    })
})
