import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

// registerComponent('column-carousel', _ => {
//     document.querySelectorAll('.column-carousel').forEach(carousel => {
//         const cols = carousel.dataset.cols || 2
//         const slider = tns({
//             container: carousel,
//             items: Math.max(2, Math.floor(cols / 2)),
//             slideBy: 1,
//             gutter: 50,
//             controls: false,
//             navPosition: 'bottom',
//             arrowKeys: true,
//             lazyload: true,
//             responsive: {
//                 768: {
//                     items: Math.max(3, Math.floor(cols * 0.75)),
//                 },
//                 992: {
//                     items: cols,
//                 },
//             },
//         })
//     })
// })


registerComponent('column-carousel', _ => {
    document.querySelectorAll('.column-carousel-wrapper').forEach(carouselWrapper => {
        const carousel = carouselWrapper.querySelector('.column-carousel')
        const cols = carousel.dataset.cols || 2

        /** @var {HTMLDivElement} prev */
        const prev = carouselWrapper.querySelector('.prev')
        /** @var {HTMLDivElement} next */
        const next = carouselWrapper.querySelector('.next')

        const slider = tns({
            container: carousel,
            items: Math.max(2, Math.floor(cols / 2)),
            slideBy: 1,
            gutter: 50,
            nav: false,
            lazyload: true,
            prevButton: prev,
            nextButton: next,
            arrowKeys: true,
            responsive: {
                768: {
                    items: Math.max(3, Math.floor(cols * 0.75)),
                },
                992: {
                    items: cols,
                },
            },
        })
    })
})
