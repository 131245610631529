$( document ).ready(function() {
    var width = $(window).width();
    if (width <= 480) {
        $('.expanded-content').each(function() { 
            var $this = $(this);            
            $this.find(':not(p:first, p:first *, .sub-content-btn)').addClass('expandable-content').addClass('d-none');    
        });
        $('.expanded-article-content').each(function() { 
            var $this = $(this);                                    
            // $("p:nth-child(5)").nextAll().addClass('expandable-content').addClass('d-none');                        
            $this.find('p:nth-child(5)').nextAll('p').addClass('expandable-article-content').addClass('d-none');                        
            // $this.find('p:last').css('display', 'none');              
        });
    }
});

$( document ).ready(function() {
    // Show expanded content
    $('.sub-content-btn').click(function() {             
        $(this).hide();
        $('.hide-sub-content-btn').show();
        var $this = jQuery(this);
        // Post Content
        var $parent = $this.parent('.expanded-content');        
        $this.toggleClass('active');
        if($this.hasClass('active')){            
            $parent.find('.expandable-content').removeClass('d-none');            
        } else {
            $parent.find('.expandable-content').addClass('d-none');                                   
            $parent.find('p:first').show();            
        }                   
    });
    // Hide expanded content
    $('.hide-sub-content-btn').click(function() {             
        $(this).hide();
        $('.sub-content-btn').show();
        var $this = jQuery(this);
        // Post Content
        var $parent = $this.parent('.expanded-content');        
        $('.sub-content-btn').toggleClass('active');
        if($this.hasClass('active')){            
            $parent.find('.expandable-content').removeClass('d-none');            
        } else {
            $parent.find('.expandable-content').addClass('d-none');                                   
            $parent.find('p:first').show();            
        }                   
    });
    
    // Show expanded content
    $('.article-content-btn').click(function() {     
        $(this).hide();
        $('.hide-article-content-btn').toggleClass('d-none');
        var $this = jQuery(this);
        // Article Content
        var $articleparent = $this.parent('.expanded-article-content');        
        $this.toggleClass('active');
        if($this.hasClass('active')){                        
            $articleparent.find('.expandable-article-content').removeClass('d-none');                 
        } else {            
            $articleparent.find('.expandable-article-content').addClass('d-none');                       
            $articleparent.find('p:first').show();                        
        }
    });

    // Hide expanded content
    $('.hide-article-content-btn').click(function() {             
        $('.article-content-btn').show();
        $(this).toggleClass('d-none');        
        var $this = jQuery(this);
        // Post Content
        var $articleparent = $this.parent('.expanded-article-content');        
        $('.article-content-btn').toggleClass('active');
        $articleparent.find('.expandable-article-content').addClass('d-none');                    
        $articleparent.find('p:first').show();                               
    });
});