import { tns } from 'tiny-slider'
import { registerComponent } from './components-front'

registerComponent('grid-carousel', _ => {
    document.querySelectorAll('.grid-carousel-wrapper').forEach(el => {
        const slider = tns({
            container: el,
            arrowKeys: true,
            nav: false,
            autoHeight: true,
            prevButton: '.grid-carousel-previous',
            nextButton: '.grid-carousel-next',
        })
    })
})
