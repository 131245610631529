addEventListener('DOMContentLoaded', _ => {
    const announcement = document.querySelector('.announcement-modal')
    if (announcement) {
        const announcementWrap = announcement.firstElementChild
        const close = announcement.querySelector('.modal-close')
        if (close) {
            close.addEventListener('click', _ => {
                announcement.remove()
            })
        }
        announcement.addEventListener('click', ev => {
            if (!announcementWrap.contains(ev.target)) {
                announcement.remove()
            }
        })
    }
})
