if (!window.COMPONENT_JAVASCRIPT) {
    window.COMPONENT_JAVASCRIPT = []
}

/**
 * Registers a component's JavaScript
 * @param {String} name
 * @param {() => void} fn
 * @param {Boolean} runOnDomLoad - If not running on DOM load, the JS will be run immediately
 * @param {String} hook
 */
export function registerComponent(name, fn, runOnDomLoad = true, hook = 'enter') {
    window.COMPONENT_JAVASCRIPT.push({ name, fn, runOnDomLoad, hook })
}

/**
 * Adds all the components that require it to the `DOMContentLoaded` event.
 * Also runs any JavaScript that doesn't require the event to be run immediately.
 */
export function start() {
    addEventListener('DOMContentLoaded', _ => {
        window.COMPONENT_JAVASCRIPT
            .filter(comp => comp.runOnDomLoad)
            .forEach(comp => {
                comp.fn()
            })
    })
    window.COMPONENT_JAVASCRIPT
        .filter(comp => !comp.runOnDomLoad)
        .forEach(comp => comp.fn())
}

/**
 * Runs every component's JavaScript
 * @param {String} hook - The lifecycle the component requires to run successfully
 */
export function initComponents(hook = 'enter') {
    window.COMPONENT_JAVASCRIPT
        .filter(comp => comp.hook === hook)
        .forEach(comp => {
            comp.fn()
        })
}
