$( document ).ready(function() {        

    $( ".link-category" ).click(function() {                          
        // Remove active class if already set
        $(".active").removeClass("active");  
        // Add active class if not set
        if (!$(this).hasClass("active")) {            
            $(this).addClass("active");
        }                      
    });    
    
});