import { registerComponent } from './components-front'

registerComponent('modal', _ => {
    ['[data-type=ComponentModal]', '[data-type=ComponentImageTextOverlay] [data-type=booking]'].forEach(selector => {
        document.querySelectorAll(selector).forEach(comp => {
            const modal = comp.querySelector('.modal-component')
            if (modal) {
                const modalWrap = modal.firstElementChild
                const btn = comp.querySelector('.modal-btn')
                const close = modal.querySelector('.modal-close')
                if (btn) {
                    btn.addEventListener('click', _ => {
                        modal.classList.remove('d-none')
                        modal.classList.add('d-flex')
                    })
                }

                if (close) {
                    close.addEventListener('click', _ => {
                        modal.classList.add('d-none')
                        modal.classList.remove('d-flex')
                    })
                }

                modal.addEventListener('click', ev => {
                    if (!modalWrap.contains(ev.target)) {
                        modal.classList.add('d-none')
                        modal.classList.remove('d-flex')
                    }
                })
            }
        })
    })
})
