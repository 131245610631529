import { registerComponent } from './components-front'

registerComponent('post-grid', _ => {
    document.querySelectorAll('[data-type=ComponentPostGrid]').forEach(comp => {
        const filters = comp.querySelector('.filters')
        const posts = [...comp.querySelectorAll('.post-item')]

        // Category Variables
        const categoryGroups = [...filters.querySelectorAll('li.filter-group')]

        // Tag Variables
        const tagFilter = filters.querySelector('#tag-filter')
        const tagSearch = tagFilter.querySelector('input')
        const tagWrap = tagFilter.querySelector('.tag-wrap')
        const activeTags = tagFilter.querySelector('.tags')

        // Search Variables
        const searchField = filters.querySelector('#keyword-search input')
        const searchLoading = filters.querySelector('#keyword-search svg.loading')
        const searchedPosts = []
        let searchDelay

        // Clear Filters
        const clearFiltersRow = comp.querySelector('#clear-filters')
        const clearFiltersButton = clearFiltersRow.querySelector('button')

        const postIsSearchedFor = post => {
            if (searchedPosts.length === 0) {
                return true
            }
            return searchedPosts.includes(parseInt(post.dataset.id))
        }

        const updateFilters = _ => {
            const activeFilters = categoryGroups.map(group => {
                const find = [...group.querySelectorAll('li')].find(item => item.dataset.active === '1')
                return find && parseInt(find.dataset.value)
            }).filter(e => e)
            activeFilters.push(...[...activeTags.querySelectorAll('.active-tag')].map(tag => {
                return parseInt(tag.dataset.value)
            }))
            if (activeFilters.length || searchField.value.length > 0) {
                clearFiltersRow.classList.remove('hidden')
            } else {
                clearFiltersRow.classList.add('hidden')
            }
            posts.forEach(post => {
                const postCats = JSON.parse(post.dataset.cats)
                if (activeFilters.every(cat => postCats.includes(cat)) && postIsSearchedFor(post)) {
                    post.classList.remove('d-none')
                    post.classList.add('d-block')
                } else {
                    post.classList.add('d-none')
                    post.classList.remove('d-block')
                }
            })
        }

        const clearFilters = _ => {
            categoryGroups.forEach(group => {
                [...group.querySelectorAll('li')]
                    .filter(item => item.dataset.active === '1')
                    .forEach(item => {
                        item.dataset.active = 0
                    })
            })

            tagWrap.querySelectorAll('li.active').forEach(tag => {
                tag.classList.remove('active')
            })
            ;[...activeTags.children].forEach(tag => {
                activeTags.removeChild(tag)
            })

            searchField.value = ''
            searchedPosts.splice(0)

            updateFilters()
        }

        /**********************
         *     Categories     *
         **********************/
        categoryGroups.forEach(group => {
            const items = [...group.querySelectorAll('li')]
            items.forEach(item => {
                item.addEventListener('click', ev => {
                    ev.stopPropagation()
                    const ul = item.closest('ul')
                    if (item.dataset.active === '1') {
                        item.dataset.active = 0
                        ul.removeAttribute('aria-activedescendant')
                    } else {
                        items.filter(item => item.dataset.active === '1').forEach(item => {
                            item.dataset.active = 0
                        })
                        item.dataset.active = 1
                        ul.setAttribute('aria-activedescendant', item.id)
                    }
                    updateFilters()
                })
            })
        })

        /**********************
         *        Tags        *
         **********************/
        tagSearch.addEventListener('input', _ => {
            if (tagSearch.value === '') {
                 tagWrap.classList.add('d-none')
            } else {
                const tags = [...tagWrap.querySelectorAll('li')]
                tags.forEach(tag => tag.classList.add('d-none'))
                const filteredTags = tags
                    .filter(tag => !tag.classList.contains('active'))
                    .filter(tag => tag.innerText.toLowerCase().includes(tagSearch.value.toLowerCase()))
                if (filteredTags.length) {
                    tagWrap.classList.remove('d-none')
                    filteredTags.forEach(tag => tag.classList.remove('d-none'))
                } else {
                    tagWrap.classList.add('d-none')
                }
            }
        })
        tagWrap.querySelectorAll('li').forEach(tag => {
            tag.addEventListener('click', _ => {
                const span = document.createElement('span')
                span.classList.add('bg-primary', 'text-white', 'p-1', 'text-sm', 'active-tag', 'rounded-lg', 'overflow-hidden')
                span.appendChild(document.createTextNode(tag.innerText))
                span.dataset.value = tag.dataset.value
                const btn = document.createElement('span')
                btn.classList.add('ml-1', 'cursor-pointer')
                btn.appendChild(document.createTextNode('\u00d7'))
                btn.addEventListener('click', _ => {
                    activeTags.removeChild(span)
                    if (activeTags.children.length === 0) {
                        activeTags.classList.replace('ml-4', 'ml-0')
                    }
                    tag.classList.remove('active')
                    updateFilters()
                })
                span.appendChild(btn)
                activeTags.appendChild(span)
                activeTags.classList.replace('ml-0', 'ml-4')
                tag.classList.add('d-none', 'active')
                tagSearch.value = ''
                tagWrap.classList.add('d-none')
                updateFilters()
            })
        })

        /**********************
         *       Search       *
         **********************/
        searchField.addEventListener('input', _ => {
            if (searchDelay) {
                clearTimeout(searchDelay)
            }
            searchDelay = setTimeout(_ => {
                if (searchField.value !== searchField.dataset.searched) {
                    searchField.dataset.searched = searchField.value
                    searchLoading.classList.remove('d-none')
                    fetch('/search', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                        },
                        body: JSON.stringify({ query: searchField.value }),
                    })
                        .catch(err => {
                            console.warn(err)
                        })
                        .then(res => res.json())
                        .then(json => {
                            searchedPosts.splice(0)
                            searchedPosts.push(...json)
                            searchLoading.classList.add('d-none')
                            updateFilters()
                        })
                        .catch(err => {
                            console.warn(err)
                        })
                }
            }, 5e2)
        })

        /**********************
         *   Clear Filters    *
         **********************/
        clearFiltersButton.addEventListener('click', _ => {
            clearFilters()
        })
    })
})
