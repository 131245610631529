import { tns } from 'tiny-slider'
import { registerComponent } from '../components-front';

registerComponent('video-gallery-slider', _ => {
    document.querySelectorAll('.video-gallery-slider').forEach(sliderWrapper => {
        const slider = sliderWrapper.querySelector('.video-gallery')

        /** @var {HTMLDivElement} prev */
        const prev = sliderWrapper.querySelector('.prev')
        /** @var {HTMLDivElement} next */
        const next = sliderWrapper.querySelector('.next')

        tns({
            container: slider,
            items: slider.dataset.mobileItems || 1,
            slideBy: 1,
            gutter: 20,
            nav: false,
            prevButton: prev,
            nextButton: next,
            arrowKeys: true,
            lazyload: true,
            responsive: {
                768: {
                    items: slider.dataset.tabletItems || 2,
                    gutter: 35,
                },
                1200: {
                    items: slider.dataset.desktopItems || 3,
                    gutter: 50,
                },
            },
        })
    })
})