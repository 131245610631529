import { registerComponent } from './components-front'

registerComponent('footnotes', _ => {
    document.querySelectorAll('#page-footnotes').forEach(footnotes => {
        const notes = []
        document.querySelectorAll('[data-footnote]').forEach((note, i) => {
            note.classList.add('footnote')
            note.dataset.footnoteCount = (i + 1).toString()
            notes.push(note.dataset.footnote)
        })
        if (notes.length > 0) {
            const list = document.createElement('ol')
            footnotes.appendChild(list)
            notes.forEach(note => {
                const item = document.createElement('li')
                list.appendChild(item)
                const noteText = document.createTextNode(note)
                item.appendChild(noteText)
            })
        }
    })
}, true, 'after')
