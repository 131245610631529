import { registerComponent } from './components-front'

registerComponent('filtered-article', _ => {
    document.querySelectorAll('.article-filter').forEach(filter => {
        const select = filter.querySelector('select')
        const button = filter.querySelector('button')
        button.addEventListener('click', _ => {
            document.querySelectorAll('.article').forEach(article => {
                const tags = JSON.parse(article.dataset.tags)
                if (isNaN(select.value) || tags.includes(+select.value)) {
                    article.classList.remove('d-none')
                } else {
                    article.classList.add('d-none')
                }
            })
        })
    })
})